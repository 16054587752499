/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1596px;
    margin: 0 auto;
}

.wrapper--narrow {
    max-width: 480px;
}
