/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: calc($unit * 6);
}

/* Field label */
.field__lbl {
    display: block;
    margin-bottom: calc($unit);
    @mixin type-scale $type-micro, 4;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: color($color-text a(0.4));
}

/* Icon on the left or right of the input */
.field__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.field__icon--left {
    left: 0;
}

.field__icon--right {
    right: 0;
}

.empty-field {
    background-color: $color-secondary;
    border-radius: $global-radial;
    padding: calc($unit * 2) calc($unit * 4);
}
