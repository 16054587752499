/*------------------------------------*\
    # component.onoffswitch
\*------------------------------------*/

.onoffswitch {
    position: relative;
    width: 72px;
    user-select: none;
}

.onoffswitch__checkbox {
    display: none;
}

.onoffswitch__label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}

.onoffswitch__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s;
}

.onoffswitch__inner:before,
.onoffswitch__inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 32px;
    padding: 0;
    @mixin type-scale $type-tiny, 8;
    color: white;
}

.onoffswitch__inner:before {
    content: 'ON';
    padding-left: calc($unit * 3);
    background-color: $color-primary;
    color: #FFFFFF;
}

.onoffswitch__inner:after {
    content: 'OFF';
    padding-right: calc($unit * 3);
    background-color: color($color-dark a(0.1));
    color: $color-dark;
    text-align: right;
}

.onoffswitch__switch {
    display: block;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    position: absolute;
    top: 8px;
    bottom: 0;
    right: 48px;
    border-radius: 50%;
    transition: all 0.2s;
}

.onoffswitch__checkbox:checked + .onoffswitch__label .onoffswitch__inner {
    margin-left: 0;
}

.onoffswitch__checkbox:checked + .onoffswitch__label .onoffswitch__switch {
    right: 10px;
}
