/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    /**
     * Btngroup base
     */
    display: inline-flex;
}

.btn-group .btn {
    margin-right: calc($unit * 3);

    &:last-child {
        margin-right: 0;
    }
}