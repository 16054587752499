/*------------------------------------*\
    # block.modal
\*------------------------------------*/

.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    width: 100%;

    opacity: 1;
    pointer-events: all;
    transform: scale(1);

    background-color: color($color-primary a(0.4));

    @media (--screen-from-sml) {
        padding: 10% 0;
    }
}

.modal__wrapper {
    position: relative;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, 0.4);

    transition: all 0.2s;

    @media (--screen-from-sml) {
        width: 50%;
        min-width: 280px;
        border-radius: $global-radial;
    }
}

.modal__content {
    padding: calc($unit * 8);
}

.modal__header {
    padding: calc($unit * 8);
}

.modal__footer {
    padding: calc($unit * 8);
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: calc($unit * 3);
    right: calc($unit * 3);
}
