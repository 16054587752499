/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/

.hamburger {
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 10px 12px 4px 12px;
    overflow: visible;
}

.hamburger-box {
    width: 32px;
    height: 30px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;

    &,
    &:before,
    &:after {
        position: absolute;
        width: 30px;
        height: 2px;

        background-color: black;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &:before,
    &:after {
        content: '';
        display: block;
    }

    &:before {
        top: -10px;
    }

    &:after {
        bottom: -10px;
    }
}

/* Elastic */
.hamburger--elastic .hamburger-inner {
    top: 4px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner:before {
    top: 8px;
    transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner:after {
    top: 16px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 8px, 0) rotate(135deg);
    transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner:before {
    transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
    transform: translate3d(0, -16px, 0) rotate(-270deg);
    transition-delay: 0.075s;
}
