/*------------------------------------*\
    # component.pagination
\*------------------------------------*/

.pagination {
    padding: calc($unit * 10) 0;
}

.pagination__wrapper {
    display: inline-flex;
}

.pagination__arrow {
    justify-content: center;
    width: 52px;
    height: 52px;
    opacity: 0.5;
}

.pagination__numbers {
    flex: 1;
    display: flex;
}

.pagination__number {
    position: relative;
    justify-content: center;
    width: 52px;
    height: 52px;

    opacity: 0.7;

    &.is-active {
        opacity: 1;
        color: $color-primary;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 30%;
            height: 2px;
            transform: translateX(-50%);

            background: $color-primary;
        }
    }
}

.pagination__dots {
    opacity: 0.6;
}
