/*------------------------------------*\
    # component.card
\*------------------------------------*/

.card {
    padding: calc($unit * 10);
    background: white;
    border-radius: $global-radial;
}

.card__header {
    padding-bottom: calc($unit * 10);
    border-bottom: 1px solid color($color-dark a(0.1));
}

.card__content {
    padding: calc($unit * 10) 0;
}

.card__footer {
    padding-top: calc($unit * 10);
    border-top: 1px solid color($color-dark a(0.1));
}
