/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted {
    p,
    ul,
    ol,
    dl,
    table,
    object,
    img {
        margin-bottom: calc($unit * 4);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: calc($unit * 8) 0;
    }

    ul,
    ol,
    dl {
        list-style: inherit;
        list-style-position: inside;
    }

    p {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
