/*------------------------------------*\
    # utils.well
\*------------------------------------*/

.u-well {
    padding: calc($unit * 5);
    border-radius: $global-radial;
}

.u-well--primary {
    background: color($color-primary a(0.1));
    border: 1px solid color($color-primary a(0.2));
}

.u-well--dropzone {
    background: color($color-primary a(0.1));
    border: 1px dashed color($color-primary a(0.2));
}
