/*------------------------------------*\
    # component.itemlist
\*------------------------------------*/

.itemlist {
}

/* Simple item list with title and action buttons */
.itemlist--simple {
}

.itemlist--simple__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: calc($unit * 4) 0;
    border-bottom: 1px solid color($color-dark a(0.1));
    transition: all .2s ease-out;

    

    &:hover {
        padding-left: calc($unit * 4);
        margin-left: calc($unit * -4);
        background: color(white a(0.7));
    }
}

.itemlist--simple__item__content {
    padding-right: calc($unit * 6);
}

.itemlist--simple__item__action {
}
