/*------------------------------------*\
    # views.badges
\*------------------------------------*/

.preview {
    position: relative;
}

.preview__header {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 60px;
    background: black;
    color: white;

    display: flex;
    align-items: center;
    padding: 0 calc($unit * 5);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 10);
    }
}

.preview__header__branding {
}

.preview__header__branding img {
    max-width: 100px;
}

.preview__header__copy {
    padding-left: calc($unit * 5);
    font-family: $ff-serif;
    font-style: italic;
}

.map-preview {
    height: 100vh;
    width: 100vw;
}

.preview__filters {
    position: absolute;
    top: calc($unit * 15);
    left: calc($unit * 0);
    width: 100vw;
    z-index: 10;

    background: white;
    box-shadow: 0 0 10px color(black a(0.1));
    
    @media (--screen-from-med) {
        border-radius: 10px;
        top: calc($unit * 20);
        left: calc($unit * 10);
        width: 300px;
    }
}

.preview__filters__city {
    padding: calc($unit * 6) calc($unit * 6) 0 calc($unit * 6);

    @media (--screen-to-med) {
        min-height: 88px;
    }
}

.preview__filters__search {
    display: none;
    padding: calc($unit * 6);
    @media (--screen-from-med) {
        display: block;
    }
}

.preview__filter-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.preview__suggestions {
    max-height: 400px;
    overflow-y: scroll;
}

.preview__suggestion {
    padding: calc($unit * 4) calc($unit * 6);
    border-top: 1px solid color(black a(0.1));
    cursor: pointer;

    &:hover {
    }

    &:first-child {
        margin-top: calc($unit * 5);
    }

    &:after {
        transition: all 0.2s ease-out;
    }
}

.preview__suggestion-active {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: $color-primary;

        transition: all 0.2s ease-out;
    }
}

.preview__suggestion__title {
    margin-bottom: calc($unit * 2);
    font-family: $ff-serif;
}

.preview__suggestion__address {
    color: color($color-text a(0.6));
}

.preview__suggestion__city {
    color: color($color-text a(0.6));
}

.preview-infobox {
    padding: calc($unit * 2);
}

.preview-infobox__content p {
    margin-bottom: calc($unit * 1);
}
