/*------------------------------------*\
    # block.auth
\*------------------------------------*/

.auth {
    margin-top: calc($unit * 30);
    background: white;
    border-radius: $global-radial;
}

.auth__header {
    padding: calc($unit * 10) calc($unit * 10) calc($unit * 5) calc($unit * 10);
}

.auth__content {
    padding: 0 calc($unit * 10);
}

.auth__action {
    padding: calc($unit * 10);
}
