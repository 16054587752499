/*------------------------------------*\
    # component.contentarea
\*------------------------------------*/

.contentarea {
    padding: calc($unit * 10) 0;
}

.contentarea__heading {
    padding-bottom: calc($unit * 8);
    margin-bottom: calc($unit * 10);
    border-bottom: 1px solid color($color-dark a(0.1));
}

.contentarea__heading__main {
    display: flex;
    justify-content: space-between;
}

.contentarea__heading__search {
    margin-top: calc($unit * 8);
}

.contentarea__heading__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentarea__heading__action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.contentarea__actionbar {
    padding-top: calc($unit * 8);
    margin-top: calc($unit * 10);
    border-top: 1px solid color($color-dark a(0.1));
}
