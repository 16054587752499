/*------------------------------------*\
    # component.imageuploader
\*------------------------------------*/

.imageuploader {
    position: relative;
    padding: calc($unit * 5);
    max-width: 320px;
    background: white;
    border-radius: $global-radial;
    border: 1px solid color($color-dark a(0.1));
}

.imageuploader__image {
    margin-bottom: calc($unit * 4);
}

.imageuploader__remove {
    position: absolute;
    top: calc($unit * 4);
    right: calc($unit * 4);
}

.imageuploader__action {
}